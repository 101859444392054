/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import { GridTable } from 'app/components/organisms/grid-table/grid-table';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import { Order } from 'app/types/order';
import React, { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './orders-list.module.less';
import { Button } from 'app/components/atoms/button/button';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useCrudDelete } from 'app/hooks/app-requests/crud/use-crud-delete';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { useCrudList } from 'app/hooks/app-requests/crud/use-crud-list';

/**
 * Columns.
 */

const createColumns = (translate: TFunction) => [
  {
    key: 'id',
    title: translate('common.table.columns.id'),
    render: (item: Order) => `${item.id.substring(0, 6)}...`
  },
  {
    key: 'pharmacy.name',
    title: translate('common.table.columns.pharmacyId')
  },
  {
    key: 'externalOrderId',
    title: translate('common.table.columns.externalOrderId')
  },
  {
    key: 'status',
    title: translate('common.table.columns.status')
  },
  {
    key: 'isAnnulled',
    title: translate('common.table.columns.isAnnulled')
  },
  {
    key: 'createdAt',
    title: translate('common.table.columns.createdAt')
  },
  {
    key: 'updatedAt',
    title: translate('common.table.columns.updatedAt')
  },
  {
    key: 'availableItems',
    title: translate('common.table.columns.availableItems'),
    render: (item: Order) => `${item.items.filter(it => !it.deletedAt).length} / ${item.items.length}`
  }
];

/**
 * Export `OrdersList` component.
 */

export function OrdersList(): JSX.Element {
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const template = useMemo((): CrudTemplate<Order> => {
    return {
      list: {
        columns: createColumns(translate),
        route: appRoutes.dashboard.users.detail.orderEdit,
        addButton: translate('common.actions.add'),
        canEdit: true,
        canRemove: true,
        resolveParams: (params: any, item?: Order) => {
          return {
            userId: params.userId,
            id: params.id ?? item?.id
          };
        },
        key: ['userPinNumbers'],
        normalizeData: (result: any) => {
          return result;
        },
        pagination: {
          pageSize: 25,
          pageSizeOptions: [10, 25, 50, 100, 500, 1000]
        },
        endpoint: apiEndpoints.userOrders,
        endpointById: apiEndpoints.userOrdersById
      },
      remove: {
        endpoint: apiEndpoints.userOrdersById
      }
    };
  }, [translate]);

  const { mutate: deleteAction, isLoading } = useCrudDelete(template, params);
  const { data } = useCrudList<Order>(template, params, {});

  const computedTemplate = useMemo(() => {
    if (!template.list) {
      return template;
    }

    template.list.columns = [
      ...createColumns(translate),
      {
        key: 'actions',
        title: translate('gridTable.labels.actions'),
        render: (item: Order) => {
          const to = resolveAppPath(appRoutes.dashboard.users.detail.orderEdit, { userId: params.userId, id: item.id });

          return (
            <>
              <Link
                onClick={event => {
                  event.stopPropagation();
                }}
                to={to}
              >
                <Button icon={<EditOutlined />} size={'small'} />
              </Link>

              <Popconfirm
                okButtonProps={{ loading: isLoading }}
                {...{ onClick: (event: any) => event.stopPropagation() }}
                onConfirm={async (event: any) => {
                  event.stopPropagation();
                  event.preventDefault();

                  await deleteAction(item);

                  return;
                }}
                title={translate('removeAction')}
              >
                <Button danger icon={<DeleteOutlined />} size={'small'} />
              </Popconfirm>
            </>
          );
        }
      }
    ];

    return template;
  }, [deleteAction, isLoading, params.userId, template, translate]);

  return (
    <>
      <div className={styles.headerContent}>
        <Title className={styles.title}>{translate('users.orders.title')}</Title>

        <Link to={resolveAppPath(appRoutes.dashboard.users.detail.ordersAdd, { userId: params.userId })}>
          <Button type={'primary'}>{translate('common.actions.add')}</Button>
        </Link>
      </div>

      <GridTable
        columns={computedTemplate.list?.columns}
        data={data?.results}
        onClickLine={(item: Order) => {
          const url = resolveAppPath(appRoutes.dashboard.users.detail.ordersDetail ?? '', {
            userId: params.userId,
            id: item.id
          });

          navigate(url);
        }}
      />
    </>
  );
}
