/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import { CrudAdd } from 'app/components/organisms/crud/crud-add';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';
import { Order } from 'app/types/order';
import { orderFormConfig } from './order-items/form-order-config';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import moment from 'moment';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import { useParams } from 'react-router-dom';

/**
 * Export `OrderCreate` component.
 */

export function OrderCreate(): JSX.Element {
  const [translate] = useTranslation();
  const { userId } = useParams();
  const template = useMemo((): CrudTemplate<Order> => {
    return {
      add: {
        formFields: orderFormConfig(translate),
        languages: ['*'],
        submitLabel: translate('common.actions.add'),
        normalizeInitialValues: () => {
          return {
            items: []
          };
        },
        normalizePayload: payload => {
          console.log('OKAY!!!');

          const cleanPayload = JSON.parse(JSON.stringify(payload));

          cleanPayload.createdAt = moment().toISOString();

          for (const item of cleanPayload.items ?? []) {
            item.quantity = parseInt(item.quantity, 10);
          }

          console.log(cleanPayload);

          return cleanPayload;
        },
        redirect: resolveAppPath(appRoutes.dashboard.users.detail.orders, { userId }),
        endpoint: apiEndpoints.userOrders
      }
    };
  }, [translate, userId]);

  return (
    <>
      <Title>{translate('users.orders.add.title')}</Title>

      <CrudAdd template={template} />
    </>
  );
}
