/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Order } from 'app/types/order';

/**
 * Get order detail.
 */

export function getUserOrderDetail(client: AxiosInstance, userId: string, id: string): Promise<Order> {
  return client
    .get(
      resolveAppUrl('userOrdersById', {
        interpolations: { userId, id }
      }),
      { withCredentials: true }
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useCryptoOverviewInvalidator`.
 */

export function useCryptoOverviewInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['userInfo']);
  }, [queryClient]);
}

/**
 * Export `useGetUserOrderDetail`.
 */

export function useGetUserOrderDetail(userId: string | undefined, id: string | undefined) {
  const client = useRequestProvider();

  return useQuery(['userOrders', userId, id], {
    queryFn: async () => {
      if (!userId || !id) {
        return null;
      }

      return getUserOrderDetail(client, userId, id);
    }
  });
}
