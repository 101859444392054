/**
 * Module dependencies.
 */

import { Modal } from 'antd';
import { Button } from 'app/components/atoms/button/button';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './therapy-code-item.styles';
import { Content } from './therapy-code-item';
import JsonView from '@franciscomorais/react18-json-view';
import { PosologyJsonView } from './posology-json-view';
import { useParams } from 'react-router-dom';
import { usePostTestTherapy } from 'app/hooks/app-requests/drugs/use-post-test-therapy';

/**
 * Export `Playground` component.
 */

export function Playground(): JSX.Element {
  const [translate] = useTranslation();
  const [isVisible, setVisible] = useState(false);
  const [data, setData] = useState({});
  const { userId } = useParams();
  const [therapy, setTherapy] = useState({});
  const { mutateAsync: postOrder, isLoading } = usePostTestTherapy({ userId });
  const refetch = useCallback(async () => {
    const result = await postOrder(data as any);

    setTherapy(result);
  }, [data, postOrder]);

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        {translate('generateTherapyCode.playground')}
      </Button>

      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          setVisible(false);
        }}
        open={isVisible}
        title={translate('generateTherapyCode.title')}
        width={'60%'}
      >
        <Wrapper>
          <PosologyJsonView setData={setData} />

          <Content refetch={() => refetch()} title={translate('generateTherapyCode.content.schema')}>
            {!isLoading && <JsonView collapsed={5} displaySize={true} src={therapy} theme={'vscode'} />}
          </Content>
        </Wrapper>
      </Modal>
    </>
  );
}
