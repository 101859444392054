/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * `Payload` type.
 */

type Payload = {
  id: string;
  status: 'closed' | 'open';
};

/**
 * Post ticket state.
 */

export async function postTicketState(client: AxiosInstance, payload: Payload): Promise<string> {
  const { id, status } = payload;

  return await client
    .post(resolveAppUrl('supportTicketStatus', { interpolations: { id, status } }), {})
    .then(({ data }) => data.data);
}

/**
 * Export `usePostTicketState`.
 */

export function usePostTicketState() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      return await postTicketState(client, payload);
    }
  });
}
