/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { OrderItem } from 'app/types/order';

/**
 * Post order item.
 */

export async function postOrderItem(client: AxiosInstance, userId: string, payload: any): Promise<string> {
  console.log('---- POST --->', payload);
  return await client
    .post(resolveAppUrl('userOrderItemToTherapy', { interpolations: { userId } }), payload)
    .then(({ data }) => data.data);
}

/**
 * Export `usePostTestTherapy`.
 */

export function usePostTestTherapy({ userId }: { userId: string | undefined }) {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: OrderItem) => {
      if (!userId) {
        throw new Error('invalid user id');
      }

      return await postOrderItem(client, userId, payload);
    }
  });
}
