/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PosologyQuantity } from 'app/types/posology';

/**
 * Get posology quantity.
 */

export function getPosologyQuantity(client: AxiosInstance): Promise<PosologyQuantity[]> {
  return client.get(resolveAppUrl('posologyEnumQuantity'), { withCredentials: true }).then(({ data }) => data?.data);
}

/**
 * Export `useGetPosologyQuantity`.
 */

export function useGetPosologyQuantity() {
  const client = useRequestProvider();

  return useQuery(['posologyEnum', 'Quantity'], {
    queryFn: async () => {
      return await getPosologyQuantity(client);
    }
  });
}
