/**
 * Module dependencies.
 */

import React from 'react';
import { Card } from '@tremor/react';
import { Title } from 'app/components/atoms/title/title';
import { useTranslation } from 'react-i18next';
import styles from './user-overview.module.less';
import { useGetExperimentalFeatures } from 'app/hooks/app-requests/user/use-get-experimental-features';
import { Checkbox } from 'app/components/atoms/forms/fields/checkbox/checkbox';
import { usePostExperimentalFeature } from 'app/hooks/app-requests/user/use-post-experimental-features';

/**
 * Export `UsersOverview` component.
 */

export function UsersOverview(): JSX.Element {
  const [translate] = useTranslation();
  const { data, refetch } = useGetExperimentalFeatures();
  const { mutateAsync: changeConfigExperimentalFeature } = usePostExperimentalFeature();

  return (
    <Card>
      <Title>{translate('users.overview.title')}</Title>

      <div className={styles.content}>
        <div className={styles.column}>
          <Title level={'h2'}>{translate('users.userInfo.experimentalFeatures.globalTitle')}</Title>

          {data?.map(item => (
            <Checkbox
              error={null}
              key={item.code}
              label={item.name}
              onChange={async value => {
                await changeConfigExperimentalFeature({
                  enabled: value.target.checked,
                  featureCode: item.code
                });

                await refetch();
              }}
              value={item.enabled}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}
