/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pickBy } from 'lodash';
import { CrudListType, CrudTemplate, ListColumns } from '../../../organisms/crud/form-types';

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import { TFunction } from 'react-i18next';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'app/components/atoms/button/button';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { SupportTicket } from 'app/types/support';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<SupportTicket> => [
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: translate('common.table.columns.email'),
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: translate('common.table.columns.phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber'
  },
  {
    title: translate('common.table.columns.subject'),
    dataIndex: 'subject',
    key: 'subject',
    size: '1fr'
  },
  {
    title: translate('common.table.columns.status'),
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: item => moment(item.updatedAt).format('DD-MM-YYYY HH:mm:ss')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<SupportTicket> {
  return {
    columns: listColumns(translate),
    filters: listFilters(translate),
    route: appRoutes.dashboard.support.openTickets,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: false,
    key: ['supportOpenTickets'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    renderActions: (props: any) => {
      console.log('##', props);

      const url = resolveAppPath(appRoutes.dashboard.support.openTicketById as any, {
        id: props.id
      });

      return (
        <Link
          onClick={event => {
            event.stopPropagation();
          }}
          to={url}
        >
          <Button icon={<MdOutlineSupportAgent />} size={'small'} />
        </Link>
      );
    },
    clickLine: (item: SupportTicket, { navigate }) => {
      const url = resolveAppPath(appRoutes.dashboard.support.openTicketById as any, {
        id: item.id
      });

      navigate(url);
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.supportOpenTickets,
    endpointById: apiEndpoints.supportOpenTicketsById
  };
}

/**
 * Config.
 */

export function createSupportOpenTicketsTemplate(translate: TFunction): CrudTemplate<SupportTicket> {
  return {
    list: list(translate)
  };
}
