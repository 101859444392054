/**
 * Module dependencies.
 */

import { OrderItem } from 'app/types/order';
import { useTranslation } from 'react-i18next';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { Expanded } from '../table/expanded';
import { Button } from 'app/components/atoms/button/button';
import { PosologyDuration, PosologyFrequency, PosologyQuantity } from 'app/types/posology';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './order-detail-item.module.less';
import moment from 'moment';

/**
 * `Props` type.
 */

type Props = {
  item: OrderItem;
  frequenciesList?: PosologyFrequency[] | undefined | null;
  durationsList?: PosologyDuration[] | undefined | null;
  quantitiesList?: PosologyQuantity[] | undefined | null;
};

/**
 * Resolve duration.
 */

function resolveDuration(durationsList: PosologyDuration[] | undefined | null, code: string): string {
  return (durationsList ?? []).find(duration => duration.code === code)?.description.pt ?? 'N/A';
}

/**
 * Resolve quantity.
 */

function resolveQuantity(quantitiesList: PosologyQuantity[] | undefined | null, code: string): string {
  return (quantitiesList ?? []).find(quantity => quantity.code === code)?.description.pt ?? 'N/A';
}

/**
 * Resolve frequency.
 */

function resolveFrequency(frequenciesList: PosologyFrequency[] | undefined | null, code: string) {
  return (frequenciesList ?? []).find(frequency => frequency.code === code)?.name.pt ?? 'N/A';
}

/**
 * Export `OrderDetailItem` component.
 */

export function OrderDetailItem(props: Props): JSX.Element {
  const { item, frequenciesList, durationsList, quantitiesList } = props;

  const [translate] = useTranslation();
  const [showPosology, setShowPosology] = useState(false);

  return (
    <div className={styles.wrapper}>
      {item.deletedAt && <div className={styles.deleted}>{translate('users.orders.detail.labels.deleted')}</div>}

      <div className={styles.highLightLabel}>{translate('users.orders.detail.labels.productCode')}</div>
      <div className={styles.highLightLabel}>{translate('users.orders.detail.labels.productName')}</div>
      <div className={styles.highLightLabel}>{translate('users.orders.detail.labels.quantity')}</div>
      <div className={styles.highLightLabel}>{translate('users.orders.detail.labels.externalItemId')}</div>

      <div className={styles.highLightContent}>{item.productCode ?? 'N/A'}</div>
      <div className={styles.highLightContent}>{item.productName ?? 'N/A'}</div>
      <div className={styles.highLightContent}>{item.quantity ?? 'N/A'}</div>
      <div className={styles.highLightContent}>{item.externalItemId ?? 'N/A'}</div>

      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.label}>{translate('users.orders.detail.labels.tax')}</div>
          <div className={styles.value}>{item.vatTax ?? 'N/A'}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>{translate('users.orders.detail.labels.price')}</div>
          <div className={styles.value}>{item.price ?? 'N/A'}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>{translate('users.orders.detail.labels.prescriptionNumber')}</div>
          <div className={styles.value}>{item.prescriptionNumber ?? 'N/A'}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>{translate('users.orders.detail.labels.renewal')}</div>
          <div className={styles.value}>{item.renewal ? 'YES' : 'NO'}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>{translate('users.orders.detail.labels.status')}</div>
          <div className={styles.value}>{item.status ?? 'N/A'}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>{translate('users.orders.detail.labels.createdAt')}</div>
          <div className={styles.value}>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') ?? 'N/A'}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>{translate('users.orders.detail.labels.updatedAt')}</div>
          <div className={styles.value}>{moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') ?? 'N/A'}</div>
        </div>
      </div>

      <div className={styles.actions}>
        <div>
          <Button onClick={() => setShowPosology(item => !item)} style={{ minWidth: 150 }} type={'primary'}>
            {translate(`users.orders.detail.actions.${showPosology ? 'hidePosology' : 'showPosology'}`)}
          </Button>
        </div>
      </div>

      <div className={styles.expand}>
        <Expanded expanded={showPosology}>
          <>
            {item.posology?.length > 0 && (
              <>
                {(item.posology ?? []).map((posology, index) => (
                  <div className={classNames(styles.expandContent)} key={posology.id}>
                    <SubTitle>{translate('users.orders.detail.posology.title', { index: index + 1 })}</SubTitle>

                    <div className={styles.posologyInstructions}>
                      <div className={styles.item}>
                        <div className={classNames(styles.label, styles.highlight)}>
                          {translate('users.orders.detail.posology.labels.instructions')}
                        </div>

                        <div className={styles.value}>{posology.instructions ?? 'N/A'}</div>
                      </div>

                      <div className={styles.item}>
                        <div className={classNames(styles.label, styles.highlight)}>
                          {translate('users.orders.detail.posology.labels.description')}
                        </div>

                        <div className={styles.value}>{posology.description ?? 'N/A'}</div>
                      </div>
                    </div>

                    <div className={styles.posologyContent}>
                      <div className={styles.item}>
                        <div className={styles.label}>{translate('users.orders.detail.posology.labels.interval')}</div>

                        <div className={styles.value}>{posology.interval ?? 'N/A'}</div>
                      </div>

                      <div className={styles.item}>
                        <div className={styles.label}>{translate('users.orders.detail.posology.labels.rest')}</div>

                        <div className={styles.value}>{posology.rest ?? 'N/A'}</div>
                      </div>

                      <div className={styles.item}>
                        <div className={styles.label}>
                          {translate('users.orders.detail.posology.labels.durationValue')}
                        </div>

                        <div className={styles.value}>{posology.durationValue ?? 'N/A'}</div>
                      </div>

                      <div className={styles.item}>
                        <div className={styles.label}>
                          {translate('users.orders.detail.posology.labels.durationUnit')}
                        </div>

                        <div className={styles.value}>{resolveDuration(durationsList, posology.durationUnit)}</div>
                      </div>

                      <div className={styles.item}>
                        <div className={styles.label}>{translate('users.orders.detail.posology.labels.createdAt')}</div>

                        <div className={styles.value}>
                          {moment(posology.createdAt).format('YYYY-MM-DD HH:mm:ss') ?? 'N/A'}
                        </div>
                      </div>

                      <div className={styles.item}>
                        <div className={styles.label}>{translate('users.orders.detail.posology.labels.updatedAt')}</div>

                        <div className={styles.value}>
                          {moment(posology.updatedAt).format('YYYY-MM-DD HH:mm:ss') ?? 'N/A'}
                        </div>
                      </div>

                      <div className={styles.posologyDosage} style={{ gridColumn: 'span 6' }}>
                        {(posology.dosage ?? []).map((dosage, index) => (
                          <div key={dosage.id}>
                            <SubTitle className={styles.subtitle}>
                              {translate('users.orders.detail.dosage.title', { index: index + 1 })}
                            </SubTitle>

                            <div className={styles.posologyContent}>
                              <div className={styles.item}>
                                <div className={styles.label}>
                                  {translate('users.orders.detail.dosage.labels.quantityValue')}
                                </div>

                                <div className={styles.value}>{dosage.quantityValue ?? 'N/A'}</div>
                              </div>

                              <div className={styles.item}>
                                <div className={styles.label}>
                                  {translate('users.orders.detail.dosage.labels.quantityUnit')}
                                </div>

                                <div className={styles.value}>
                                  {resolveQuantity(quantitiesList, dosage.quantityUnit)}
                                </div>
                              </div>

                              <div className={styles.item}>
                                <div className={styles.label}>
                                  {translate('users.orders.detail.dosage.labels.frequencyUnit')}
                                </div>

                                <div className={styles.value}>{dosage.frequencyUnit ?? 'N/A'}</div>
                              </div>

                              <div className={styles.item}>
                                <div className={styles.label}>
                                  {translate('users.orders.detail.dosage.labels.frequencyValue')}
                                </div>

                                <div className={styles.value}>
                                  {resolveFrequency(frequenciesList, dosage.frequencyValue)}
                                </div>
                              </div>

                              <div className={styles.item}>
                                <div className={styles.label}>
                                  {translate('users.orders.detail.dosage.labels.createdAt')}
                                </div>

                                <div className={styles.value}>
                                  {moment(dosage.updatedAt).format('YYYY-MM-DD HH:mm:ss') ?? 'N/A'}
                                </div>
                              </div>

                              <div className={styles.item}>
                                <div className={styles.label}>
                                  {translate('users.orders.detail.dosage.labels.updatedAt')}
                                </div>

                                <div className={styles.value}>
                                  {moment(dosage.updatedAt).format('YYYY-MM-DD HH:mm:ss') ?? 'N/A'}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        </Expanded>
      </div>
    </div>
  );
}
