/**
 * Module dependencies.
 */

import React, { useContext } from 'react';
import { Select as AntdSelect } from 'antd';
import { get, set } from 'lodash';
import { JsonViewContext } from '@franciscomorais/react18-json-view';

/**
 * `Props` type.
 */

type Props = {
  params: any;
  node: any;
  options: Array<{
    label: any;
    value: string;
    metadata: any;
  }>;
};

/**
 * Export `DrugSelector` component.
 */

export function DrugSelector({ params, options }: Props): JSX.Element {
  const context = useContext(JsonViewContext);
  const productId = params.parentPath.join('.');
  const value = get(context.src, productId);

  return (
    <div style={{ display: 'inline-block' }}>
      <AntdSelect
        allowClear
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        onChange={(id, { metadata }: any) => {
          const parentList = [...params.parentPath].slice(0, -1);
          const productCode = [...parentList, 'productCode'].join('.');
          const productName = [...parentList, 'productName'].join('.');

          set(context.src, productId, metadata.id);
          set(context.src, productCode, metadata.registry);
          set(context.src, productName, JSON.parse(metadata.name).pt);

          context.forceUpdate();
          context.onChange?.({ src: context.src } as any);
        }}
        options={options}
        showSearch
        style={{ width: 600 }}
        value={value}
      />
    </div>
  );
}
