/**
 * Module dependencies.
 */

import { Modal } from 'antd';
import { Button } from 'app/components/atoms/button/button';
import { Order } from 'app/types/order';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TherapyCodeItem } from './therapy-code-item';

/**
 * `Props` type.
 */

type Props = {
  data: Order | null | undefined;
};

/**
 * Export `GenerateTherapyCode` component.
 */

export function GenerateTherapyCode({ data }: Props): JSX.Element {
  const [translate] = useTranslation();
  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        {translate('generateTherapyCode.action')}
      </Button>

      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          setVisible(false);
        }}
        open={isVisible}
        title={translate('generateTherapyCode.title')}
        width={'60%'}
      >
        {(data?.items ?? []).map(item => (
          <TherapyCodeItem key={item.id} orderItem={item} />
        ))}
      </Modal>
    </>
  );
}
