/**
 * Module dependencies.
 */

import React, { useCallback, useEffect } from 'react';
import styles from '../orders-list.module.less';
import { CrudTemplate, WrapperWidget } from 'app/components/organisms/crud/form-types';
import { Language } from 'app/types/language';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Button } from 'app/components/atoms/button/button';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import { CrudFormRender } from 'app/components/organisms/crud/crud-form';
import classNames from 'classnames';
import { dosageFormField } from './form-dosage-config';

/**
 * `Props` type.
 */

type Props<T> = {
  children: JSX.Element;
  template: CrudTemplate<T>;
  languages: Language[];
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  metadata: any;
  formType: 'add' | 'edit';
  name: string;
  item: WrapperWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `DosageForm` component.
 */

export function DosageForm<T>({ item, ...rest }: Props<T>): JSX.Element {
  const [translate] = useTranslation();

  const formFields = useCallback(
    (index: number) => {
      return dosageFormField(translate, `${item.metadata.prefix}.dosage[${index}]`);
    },
    [item.metadata.prefix, translate]
  );

  const lines = rest.form.getValues(`${item.metadata.prefix}.dosage`);

  useEffect(() => {
    if (lines === undefined) {
      rest.form.setValue(`${item.metadata.prefix}.dosage`, []);

      return;
    }
  }, [item.metadata.prefix, lines, rest.form]);

  return (
    <div className={styles.posologiesForm}>
      {range(lines?.length).map(index => (
        <div className={classNames(styles.addWrapper, styles.level3)} key={index}>
          <div className={styles.wrapperHeader}>
            {'Dosage: '}
            {index + 1}
          </div>

          <CrudFormRender {...rest} formFields={formFields(index)} />
        </div>
      ))}

      <div className={classNames(styles.orderItemHeader, styles.actionsPadding)}>
        <div>
          <Button
            onClick={() => {
              rest.form.setValue(`${item.metadata.prefix}.dosage`, [...lines, {}]);
            }}
            type={'primary'}
          >
            {translate('users.orders.add.actions.addDosage')}
          </Button>
        </div>

        {lines?.length > 0 && (
          <div>
            <Button
              danger
              disabled={lines === 0}
              onClick={() => {
                const newLines = lines.slice(0, lines.length - 1);

                rest.form.setValue(`${item.metadata.prefix}.dosage`, newLines);
              }}
            >
              {translate('users.orders.add.actions.removeLine')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
