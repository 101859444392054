/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import React, { useMemo } from 'react';
import { createSupportOpenTicketsTemplate } from '../open-tickets/form-config';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCrudById } from 'app/hooks/app-requests/crud/use-crud-by-id';
import { Loading } from 'app/components/atoms/loading/loading';
import { Card, Typography } from 'antd';
import { Field } from 'app/components/organisms/field/field';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { ReplyMessage } from './reply-message';
import moment from 'moment';
import style from './ticket-detail.module.less';
import { Message } from './message';

/**
 * Export `TicketDetail` component.
 */

export function TicketDetail(): JSX.Element {
  const [translate] = useTranslation();
  const supportOpenTicketsTemplate = useMemo(() => createSupportOpenTicketsTemplate(translate), [translate]);
  const params = useParams();
  const { data, isLoading, refetch } = useCrudById(supportOpenTicketsTemplate, params);
  const [headMessage, ...messages] = useMemo(() => data?.thread ?? [], [data]);

  console.log(data);

  return (
    <Loading isLoading={isLoading}>
      <Title>{translate('support.ticketDetail.title')}</Title>

      <div className={style.header}>
        <Card className={style.card}>
          <Field first title={translate('common.labels.name')} value={data?.name ?? 'N/A'} />

          <Field title={translate('common.labels.email')} value={data?.email ?? 'N/A'} />

          <Field title={translate('common.labels.phoneNumber')} value={data?.phoneNumber ?? 'N/A'} />

          <Field last title={translate('common.labels.name')} value={data?.name ?? 'N/A'} />
        </Card>

        <Card className={style.card}>
          <Field first title={translate('common.labels.status')} value={data?.status ?? 'N/A'} />

          <Field
            title={translate('common.labels.createdAt')}
            value={moment(data?.createdAt).format('DD-MM-YYYY HH:mm:ss') ?? 'N/A'}
          />

          <Field
            last
            title={translate('common.labels.updatedAt')}
            value={moment(data?.updatedAt).format('DD-MM-YYYY HH:mm:ss') ?? 'N/A'}
          />
        </Card>
      </div>

      <SubTitle className={style.subtitle}>{data?.subject ?? 'foo'}</SubTitle>

      <div className={style.thread}>
        <Card>
          <Typography.Text>{headMessage?.message}</Typography.Text>

          <div className={style.headMessageBottom}>
            {translate('common.labels.createdAtDescription', {
              date: moment(headMessage?.createdAt).format('DD-MM-YYYY HH:mm:ss')
            })}
          </div>
        </Card>

        {messages?.map(item => (
          <Message item={item} key={item.id} userName={data?.name ?? data?.email ?? data?.phoneNumber ?? ''} />
        ))}

        <ReplyMessage refresh={refetch} ticket={data} />
      </div>
    </Loading>
  );
}
