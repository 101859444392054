/**
 * Module dependencies.
 */

import { useEffect, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
  isVisible: boolean;
  delay: number;
};

/**
 * Export `DelayRender` component.
 */

export function DelayRender({ children, delay, isVisible }: Props): JSX.Element | null {
  const [isShow, setShow] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setShow(true);

      return;
    }

    const timer = setTimeout(() => {
      setShow(false);
    }, delay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [delay, isVisible]);

  if (!isShow) {
    return null;
  }

  return children;
}
