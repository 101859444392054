/**
 * Module dependencies.
 */

import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { FormListField } from 'app/components/organisms/crud/form-types';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { numericRule, requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/app-requests/crud/use-crud-request';
import { Drug } from 'app/types/drug';
import { TFunction } from 'i18next';
import { compact, get } from 'lodash';
import React from 'react';
import styles from '../orders-list.module.less';
import { PosologiesForm } from './posologies-form';

/**
 * Export `orderItemsFormField` function.
 */

export const orderItemsFormField = (translate: TFunction, prefix = ''): FormListField[] => [
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'externalItemId']).join('.'),
            label: translate('users.orders.add.labels.externalItemId'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'vatTax']).join('.'),
            label: translate('users.orders.add.labels.vatTax'),
            rules: undefined
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'quantity']).join('.'),
            label: translate('users.orders.add.labels.quantity'),
            rules: numericRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'price']).join('.'),
            label: translate('users.orders.add.labels.price'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'selectField',
            name: compact([prefix, 'productId']).join('.'),
            showSearch: true,
            allowClear: true,
            rules: undefined,
            onChange: (value, option, form) => {
              form.resetField(compact([prefix, 'productCode']).join('.'));
              form.resetField(compact([prefix, 'productName']).join('.'));
              form.setValue(compact([prefix, 'productCode']).join('.'), (option as any)?.registry);
              form.setValue(compact([prefix, 'productName']).join('.'), (option as any)?.label);
            },
            label: translate('users.orders.add.labels.product'),
            options: {
              hook: useCrudRequest as any,
              hookProps: () => {
                return [
                  {
                    key: ['products'],
                    options: { interpolations: {} },
                    endpoint: apiEndpoints.drugs
                  }
                ];
              },
              normalize: (items: Drug[]) => {
                return (
                  items?.map(item => ({
                    value: item.id,
                    registry: item.registry,
                    label: JSON.parse(item.name).pt,
                    searchValue: JSON.parse(item.name).pt
                  })) ?? []
                );
              }
            }
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputField',
            disabled: values => {
              return !!get(values, compact([prefix, 'productId']).join('.'));
            },
            name: compact([prefix, 'productCode']).join('.'),
            rules: requiredRule(translate),
            label: translate('users.orders.add.labels.productCode')
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputField',
            disabled: values => {
              return !!get(values, compact([prefix, 'productCode']).join('.'));
            },
            name: compact([prefix, 'productName']).join('.'),
            rules: requiredRule(translate),
            label: translate('users.orders.add.labels.productName')
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'prescriptionNumber']).join('.'),
            label: translate('users.orders.add.labels.prescriptionNumber'),
            rules: undefined
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'checkBoxField',
            name: compact([prefix, 'renewal']).join('.'),
            label: translate('users.orders.add.labels.renewal'),
            rules: undefined
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'selectField',
            name: compact([prefix, 'status']).join('.'),
            label: translate('users.orders.add.labels.status'),
            rules: requiredRule(translate),
            options: [
              {
                value: 'pending',
                label: String(translate('users.orders.add.labels.statusLabels.pending'))
              },
              {
                value: 'ready',
                label: String(translate('users.orders.add.labels.statusLabels.ready'))
              },
              {
                value: 'completed',
                label: String(translate('users.orders.add.labels.statusLabels.completed'))
              }
            ]
          }
        ]
      }
    ]
  },
  {
    type: 'inputField',
    inputType: 'textArea',
    name: compact([prefix, 'instructions']).join('.'),
    label: translate('users.orders.add.labels.instructions'),
    rules: undefined
  },
  {
    type: 'render',
    name: 'render_2',
    render: (
      <div className={styles.orderItemHeader}>
        <SubTitle>{translate('users.orders.add.posologyItems')}</SubTitle>
      </div>
    )
  },
  {
    type: 'wrapper',
    name: 'wrapper',
    metadata: { prefix },
    wrapperComponent: PosologiesForm,
    formFields: [
      /* inside component */
    ]
  }
];
