/**
 * Module dependencies.
 */

import { FormListField } from 'app/components/organisms/crud/form-types';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/app-requests/crud/use-crud-request';
import { PosologyFrequency, PosologyQuantity } from 'app/types/posology';
import { TFunction } from 'i18next';
import { compact } from 'lodash';

/**
 * Export `dosageFormField` function.
 */

export const dosageFormField = (translate: TFunction, prefix = ''): FormListField[] => [
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 6,
        children: [
          {
            type: 'selectField',
            name: compact([prefix, 'quantityUnit']).join('.'),
            showSearch: true,
            allowClear: true,
            rules: requiredRule(translate),
            label: translate('users.orders.add.labels.quantityUnit'),
            options: {
              hook: useCrudRequest as any,
              hookProps: () => {
                return [
                  {
                    key: ['quantityUnit'],
                    options: { interpolations: {} },
                    endpoint: apiEndpoints.posologyEnumQuantity
                  }
                ];
              },
              normalize: (items: PosologyQuantity[]) => {
                return (
                  items?.map(item => ({
                    value: item.code,
                    label: item.description?.pt,
                    searchValue: item.description?.pt
                  })) ?? []
                );
              }
            }
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'quantityValue']).join('.'),
            label: translate('users.orders.add.labels.quantityValue'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'frequencyUnit']).join('.'),
            label: translate('users.orders.add.labels.frequencyUnit'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'selectField',
            name: compact([prefix, 'frequencyValue']).join('.'),
            showSearch: true,
            allowClear: true,
            rules: requiredRule(translate),
            label: translate('users.orders.add.labels.frequencyValue'),
            options: {
              hook: useCrudRequest as any,
              hookProps: () => {
                return [
                  {
                    key: ['frequencyValue'],
                    options: { interpolations: {} },
                    endpoint: apiEndpoints.posologyEnumFrequency
                  }
                ];
              },
              normalize: (items: PosologyFrequency[]) => {
                return (
                  items?.map(item => ({
                    value: item.code,
                    label: item.name?.pt,
                    searchValue: item.name?.pt
                  })) ?? []
                );
              }
            }
          }
        ]
      }
    ]
  }
];
