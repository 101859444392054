/**
 * Module dependencies.
 */

import React, { ReactNode, useMemo } from 'react';
import styles from './table.module.less';
import classnames from 'classnames';
import { castFunction } from 'app/core/utils/template';
import { get } from 'lodash';

/**
 * `Column` type.
 */

export type Column<T> = {
  title: string;
  supportLocale?: boolean;
  size?: string;
  dataIndex?: string;
  key: string;
  mobile?: {
    direction?: 'column' | 'row';
  };
  style?: any;
  styleRow?: any;
  render?: (item: T) => ReactNode;
};

/**
 * `Props` type.
 */

type Props<T> = Column<T> & {
  index: number;
  item: T;
  currentLanguage: string;
};

/**
 * Export `Row` component.
 */

export function Row<T>(props: Props<T>): JSX.Element {
  const { key, index, title, dataIndex, supportLocale, mobile, currentLanguage, render, style, styleRow } = props;
  const item = useMemo(() => {
    let label = get(props.item, dataIndex ?? key);

    if (supportLocale) {
      try {
        label = JSON.parse(label)?.[currentLanguage];
      } catch (error) {
        // Nothing to do.
      }
    }

    return {
      ...props.item,
      [dataIndex ?? key]: label
    };
  }, [currentLanguage, dataIndex, key, props.item, supportLocale]);

  return (
    <div
      className={classnames(styles.cellContent, {
        [styles.verticalAlignment]: mobile?.direction === 'column'
      })}
    >
      <div className={styles.cellHeader}>{title}</div>

      <div
        className={classnames(styles.cell, {
          [styles.oddColor]: index % 2 === 0
        })}
        style={{
          ...castFunction(style, item),
          ...castFunction(styleRow, item)
        }}
      >
        {!!render ? render(item) : get(item, dataIndex ?? key)}
      </div>
    </div>
  );
}
