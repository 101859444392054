/**
 * Module dependencies.
 */

import { OrderItem } from 'app/types/order';
import React, { ReactNode } from 'react';
import { ContentTitle, ContentWrapper, RefetchButton, Wrapper } from './therapy-code-item.styles';
import JsonView from '@franciscomorais/react18-json-view';
import { useTranslation } from 'react-i18next';
import { useGetOrderItemFormCode } from 'app/hooks/app-requests/user-orders/use-get-order-item-form-code';
import { useParams } from 'react-router-dom';

/**
 * `Props` type.
 */

type Props = {
  orderItem: OrderItem;
};

/**
 * `ContentProps` type.
 */

type ContentProps = {
  children: ReactNode;
  title: string;
  refetch?: () => void;
};

/**
 * `Content` component.
 */

export function Content({ title, children, refetch }: ContentProps): JSX.Element {
  const [translate] = useTranslation();

  return (
    <ContentWrapper>
      <ContentTitle>{title}</ContentTitle>

      {refetch && (
        <RefetchButton onClick={refetch} size={'small'} type={'primary'}>
          {translate('generateTherapyCode.content.refetch')}
        </RefetchButton>
      )}

      {children}
    </ContentWrapper>
  );
}

/**
 * Export `TherapyCodeItem` component.
 */

export function TherapyCodeItem({ orderItem }: Props): JSX.Element {
  const [translate] = useTranslation();
  const params = useParams();
  const { data, refetch } = useGetOrderItemFormCode(params.userId, orderItem.id);

  return (
    <Wrapper>
      {orderItem.productName}

      <Content title={translate('generateTherapyCode.content.item')}>
        <JsonView collapsed={0} displaySize={true} src={orderItem} theme={'vscode'} />
      </Content>
      {!!data && (
        <Content refetch={() => refetch()} title={translate('generateTherapyCode.content.schema')}>
          <JsonView collapsed={0} displaySize={true} src={data} theme={'vscode'} />
        </Content>
      )}
    </Wrapper>
  );
}
