/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PosologyDuration } from 'app/types/posology';

/**
 * Get posology durations.
 */

export function getPosologyDurations(client: AxiosInstance): Promise<PosologyDuration[]> {
  return client.get(resolveAppUrl('posologyEnumDuration'), { withCredentials: true }).then(({ data }) => data?.data);
}

/**
 * Export `useGetPosologyDuration`.
 */

export function useGetPosologyDuration() {
  const client = useRequestProvider();

  return useQuery(['posologyEnum', 'Duration'], {
    queryFn: async () => {
      return await getPosologyDurations(client);
    }
  });
}
