/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import styled from 'styled-components';

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div`
  background-color: #fcfcfc;
  border: 1px solid #e0e0e0;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 8px;
`;

/**
 * Export `ContentWrapper` styled component.
 */

export const ContentWrapper = styled(Wrapper)`
  margin-bottom: 0;
  margin-top: 24px;
  position: relative;
`;

/**
 * Export `ContentTitle` styled component.
 */

export const ContentTitle = styled.div`
  background-color: #fcfcfc;
  display: inline-block;
  padding: 0 10px;
  position: absolute;
  top: -13px;
  font-weight: 500;
  font-size: 16px;
`;

/**
 * Export `RefetchButton` styled component.
 */

export const RefetchButton = styled(Button)`
  position: absolute;
  top: -11px;
  right: 32px;
`;
