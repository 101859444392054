/**
 * Module dependencies.
 */

import { SupportMessage } from 'app/types/support';
import React from 'react';
import style from './ticket-detail.module.less';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'antd';
import moment from 'moment';
import classNames from 'classnames';

/**
 * `Props` type.
 */

type Props = {
  item: SupportMessage;
  userName: string;
};

/**
 * Export `Message` component.
 */

export function Message({ item, userName }: Props): JSX.Element {
  const [translate] = useTranslation();

  return (
    <div
      className={classNames(style.message, {
        [style.left]: item?.messageType === 'user',
        [style.right]: item?.messageType === 'admin'
      })}
    >
      <Card className={style.card}>
        <Typography.Text>{item?.message}</Typography.Text>

        <div className={style.headMessageBottom}>
          {translate('common.labels.createdAtDescription', {
            date: moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss')
          })}
        </div>
      </Card>

      <div className={style.userInfo}>
        <div className={style.avatar} />
        <div className={style.name}>{item?.user?.name ?? item?.user?.email ?? userName}</div>
        <div className={style.type}>{translate(`support.ticketDetail.type.${item?.messageType}`)}</div>
      </div>
    </div>
  );
}
