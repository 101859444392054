/**
 * Module dependencies.
 */

import React, { useEffect, useMemo, useRef } from 'react';
import JsonView from '@franciscomorais/react18-json-view';
import { DrugSelector } from './playground-components/drug-selector';
import { PlaygroundInput } from './playground-components/playground-input';
import { useGetDrugs } from 'app/hooks/app-requests/drugs/use-get-drugs';
import { useGetPosologyDuration } from 'app/hooks/app-requests/drugs/use-get-posology-durations';
import { useGetPosologyFrequency } from 'app/hooks/app-requests/drugs/use-get-posology-frequency';
import { useGetPosologyQuantity } from 'app/hooks/app-requests/drugs/use-get-posology-quantity';
import { PlaygroundSelector } from './playground-components/playground-selector';
import { set } from 'lodash';

/**
 * Dosage.
 */

const defaultDosage = {
  id: '8cd33dd0-fc03-4990-a43e-a2f3b17622d4',
  posologyId: 'ed103867-dfbd-4d02-adf6-c7e7e944e065',
  dosageIteration: 0,
  createdAt: '2024-09-05T16:02:00.914Z',
  updatedAt: '2024-09-05T16:02:00.914Z',
  quantityValue: '1',
  quantityUnit: '428673006',
  frequencyValue: '307466001',
  frequencyUnit: '1'
};

/**
 * Default Posology.
 */

const defaultPosology = {
  id: 'default_id',
  itemId: 'default_item_id',
  productId: '45706',
  productCode: '5056387',
  productName: 'Abilify Comp 10 mg 28 unidades',
  description: '1 application, 12/12h, 1 week',
  instructions: '2',
  interval: '1',
  rest: 'N',
  dosage: [],
  durationValue: '2',
  durationUnit: '2'
};

/**
 * Order item.
 */

const orderItem = {
  id: 'posology_test',
  posology: [
    {
      ...defaultPosology,
      dosage: [{ ...defaultDosage }]
    }
  ],
  posologyId: null
};

function checkPath(path: string, pattern: string): boolean {
  const patternParts = pattern.split('.');
  const patternPath = path.split('.');

  if (patternParts.length !== patternPath.length) {
    return false;
  }

  for (let i = 0; i < patternParts.length; i++) {
    if (patternPath[i] === patternParts[i]) {
      continue;
    }

    if (patternParts[i] === '*' && !isNaN(parseInt(patternPath[i]))) {
      continue;
    }

    return false;
  }

  return true;
}

/**
 * Customize node.
 */

function customizeNode(context: any): any {
  return (params: any) => {
    params.path = params.parentPath.join('.');

    if (params.indexOrName === 'posology') {
      return {};
    }

    if (checkPath(params.path, 'posology.*')) {
      return { add: false, delete: true, edit: false };
    }

    if (params.indexOrName === 'productId') {
      return function Component(node: any) {
        return <DrugSelector node={node} options={context.current.drugsOptions} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.productCode')) {
      return function Component(node: any) {
        return <PlaygroundInput node={node} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.productName')) {
      return function Component(node: any) {
        return <PlaygroundInput node={node} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.description')) {
      return function Component(node: any) {
        return <PlaygroundInput node={node} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.instructions')) {
      return function Component(node: any) {
        return <PlaygroundInput node={node} params={params} />;
      };
    }

    /**
     * Duration.
     */

    if (checkPath(params.path, 'posology.*.durationValue')) {
      return function Component(node: any) {
        return <PlaygroundInput node={node} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.durationUnit')) {
      return function Component(node: any) {
        return <PlaygroundSelector node={node} options={context.current.durationOptions} params={params} />;
      };
    }

    /**
     * Dosage.
     */

    if (checkPath(params.path, 'posology.*.dosage')) {
      return {};
    }

    if (checkPath(params.path, 'posology.*.dosage.*')) {
      return { add: false, delete: true, edit: false };
    }

    if (checkPath(params.path, 'posology.*.dosage.*.quantityUnit')) {
      return function Component(node: any) {
        return <PlaygroundSelector node={node} options={context.current.quantityOptions} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.dosage.*.quantityValue')) {
      return function Component(node: any) {
        return <PlaygroundInput node={node} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.dosage.*.frequencyUnit')) {
      return function Component(node: any) {
        return <PlaygroundInput node={node} params={params} />;
      };
    }

    if (checkPath(params.path, 'posology.*.dosage.*.frequencyValue')) {
      return function Component(node: any) {
        return <PlaygroundSelector node={node} options={context.current.frequencyOptions} params={params} />;
      };
    }

    return { add: false, delete: false, edit: false };
  };
}

/**
 * `Props` type.
 */

type Props = {
  setData: (value: any) => void;
};

/**
 * Export `PosologyJsonView` component.
 */

export function PosologyJsonView({ setData }: Props): JSX.Element {
  const { data } = useGetDrugs();
  const { data: posologyDuration } = useGetPosologyDuration();
  const { data: posologyFrequency } = useGetPosologyFrequency();
  const { data: posologyQuantity } = useGetPosologyQuantity();
  const context = useRef<any>();
  const drugsOptions = useMemo(
    () => data?.map(item => ({ label: JSON.parse(item.name).pt, value: item.id, metadata: item })),
    [data]
  );

  const durationOptions = useMemo(
    () => posologyDuration?.map(item => ({ label: item.description.pt, value: item.code, metadata: item })),
    [posologyDuration]
  );

  const frequencyOptions = useMemo(
    () => posologyFrequency?.map(item => ({ label: item.name.pt, value: item.code, metadata: item })),
    [posologyFrequency]
  );

  const quantityOptions = useMemo(
    () => posologyQuantity?.map(item => ({ label: item.description.pt, value: item.code, metadata: item })),
    [posologyQuantity]
  );

  context.current = {
    drugsOptions,
    durationOptions,
    frequencyOptions,
    quantityOptions
  };

  useEffect(() => {
    setData(orderItem);
  }, [setData]);

  return (
    <JsonView
      customizeNode={customizeNode(context)}
      displaySize={true}
      editable
      onAdd={(params: any) => {
        params.path = params.parentPath.join('.');

        if (checkPath(params.path, 'posology')) {
          params.src.posology[params.indexOrName] = { ...defaultPosology };
        }

        if (checkPath(params.path, 'posology.*.dosage')) {
          set(params.src, [params.path, params.indexOrName].join('.'), { ...defaultDosage });
        }
      }}
      onChange={data => {
        setData({ ...data.src });
      }}
      src={orderItem}
      theme={'vscode'}
    />
  );
}
