/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Drug } from 'app/types/drug';

/**
 * Get drugs.
 */

export function getDrugs(client: AxiosInstance): Promise<Drug[]> {
  return client.get(resolveAppUrl('drugs'), { withCredentials: true }).then(({ data }) => data?.data);
}

/**
 * Export `useGetDrugs`.
 */

export function useGetDrugs() {
  const client = useRequestProvider();

  return useQuery(['drugs'], {
    queryFn: async () => {
      return await getDrugs(client);
    }
  });
}
