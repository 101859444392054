/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import { OrderDetailItem } from 'app/components/organisms/order-detail-item/order-detail-item';
import { useCrudRequest } from 'app/hooks/app-requests/crud/use-crud-request';
import { useGetUserOrderDetail } from 'app/hooks/app-requests/user-orders/use-get-order-detail';
import { PosologyDuration, PosologyFrequency, PosologyQuantity } from 'app/types/posology';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styles from './orders-list.module.less';
import classNames from 'classnames';
import { GenerateTherapyCode } from './components/generate-therapy-code';
import { Playground } from './components/playground';

/**
 * Export `OrdersDetail` component.
 */

export function OrdersDetail(): JSX.Element {
  const [translate] = useTranslation();
  const { userId, id } = useParams();
  const { data } = useGetUserOrderDetail(userId, id);
  const { data: durationsList } = useCrudRequest<PosologyDuration[]>({
    endpoint: 'posologyEnumDuration',
    key: 'posologyEnumDuration',
    options: undefined
  });

  const { data: frequenciesList } = useCrudRequest<PosologyFrequency[]>({
    endpoint: 'posologyEnumFrequency',
    key: 'posologyEnumFrequency',
    options: undefined
  });

  const { data: quantitiesList } = useCrudRequest<PosologyQuantity[]>({
    endpoint: 'posologyEnumQuantity',
    key: 'posologyEnumQuantity',
    options: undefined
  });

  console.log('JSON data: ', JSON.stringify(data, null, 2));

  return (
    <>
      <div className={styles.headerContent}>
        <Title>{translate('users.orders.detail.title')}</Title>

        <div className={styles.actions}>
          <Playground />

          <GenerateTherapyCode data={data} />
        </div>
      </div>

      <div className={styles.header}>
        <div className={classNames(styles.item, styles.topLeft)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.pharmacy')}</div>
          <div className={styles.value}>{data?.pharmacy?.name ?? 'N/A'}</div>
        </div>

        <div className={classNames(styles.item)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.provider')}</div>
          <div className={styles.value}>{data?.provider ?? 'N/A'}</div>
        </div>

        <div className={classNames(styles.item)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.externalUserId')}</div>
          <div className={styles.value}>{data?.externalUserId ?? 'N/A'}</div>
        </div>

        <div className={classNames(styles.item, styles.topRight)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.externalOrderId')}</div>
          <div className={styles.value}>{data?.externalOrderId ?? 'N/A'}</div>
        </div>

        <div className={classNames(styles.item, styles.extraTop, styles.bottomLeft)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.vatNumber')}</div>
          <div className={styles.value}>{data?.vatNumber ?? 'N/A'}</div>
        </div>

        <div className={classNames(styles.item, styles.extraTop)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.isAnnulled')}</div>
          <div className={styles.value}>{data?.isAnnulled ? 'YES' : 'NO'}</div>
        </div>

        <div className={classNames(styles.item, styles.extraTop)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.itemsCount')}</div>
          <div className={styles.value}>{data?.items.length ?? 'N/A'}</div>
        </div>

        <div className={classNames(styles.item, styles.extraTop, styles.bottomRight)}>
          <div className={styles.label}>{translate('users.orders.detail.labels.status')}</div>
          <div className={styles.value}>{data?.status ?? 'N/A'}</div>
        </div>
      </div>

      {(data?.items ?? []).map(item => (
        <OrderDetailItem
          durationsList={durationsList}
          frequenciesList={frequenciesList}
          item={item}
          key={item.id}
          quantitiesList={quantitiesList}
        />
      ))}
    </>
  );
}
