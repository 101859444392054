/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * `Payload` type.
 */

type Payload = {
  userId: string;
  featureCode: string;
  enabled: boolean;
};

/**
 * Change experimental feature.
 */

export function changeExperimentalFeature(client: AxiosInstance, payload: Payload): Promise<void> {
  return client
    .post(resolveAppUrl('userChangeExperimentalFeature', { interpolations: { userId: payload.userId } }), {
      featureCode: payload.featureCode,
      enabled: payload.enabled
    })
    .then(({ data }) => data);
}

/**
 * Export `usePostUserExperimentalFeature`.
 */

export function usePostUserExperimentalFeature() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      return await changeExperimentalFeature(client, payload);
    }
  });
}
