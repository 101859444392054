/**
 * Module dependencies.
 */

import React, { useMemo, useState } from 'react';
import styles from './grid-table.module.less';
import { get } from 'lodash';
import { Expanded } from '../table/expanded';
import { DelayRender } from 'app/components/atoms/delay-render/delay-render';
import { ListColumns } from '../crud/form-types';
import { Column } from '../table/row';

/**
 * `Props` type.
 */

type Props<T> = {
  columns: ListColumns<T> | undefined;
  data: T[] | undefined;
  onClickLine?: (item: T) => void;
  ExpandedLineRender?: ({ item }: { item: T }) => JSX.Element;
};

/**
 * Export `GridTable` component.
 */

export function GridTable<T>({ columns, data, onClickLine, ExpandedLineRender }: Props<T>): JSX.Element {
  const [expandedLine, setExpandedLine] = useState<string | undefined | null>();
  const { cols, mainStyle } = useMemo(() => {
    const cols: Column<T>[] = [...(columns ?? [])];

    return {
      cols,
      mainStyle: {
        gridTemplateColumns: cols.map(() => 'auto').join(' ')
      }
    };
  }, [columns]);

  return (
    <div className={styles.wrapper} style={mainStyle}>
      {cols.map(item => {
        return (
          <div className={styles.header} key={item.key}>
            {item.title}
          </div>
        );
      })}

      {(data ?? []).map((item, index) => {
        return (
          <>
            <div
              className={styles.line}
              key={index}
              onClick={() => {
                if (onClickLine) {
                  onClickLine(item);
                } else {
                  setExpandedLine(id => (id === (item as any).id ? null : (item as any).id));
                }
              }}
            >
              {cols.map(col => {
                return (
                  <div className={styles.cell} key={`${index}-${col.key}`}>
                    {col.render ? col.render(item) : get(item, col.key)}
                  </div>
                );
              })}
            </div>

            {!!ExpandedLineRender && (
              <div
                className={styles.expandedLine}
                style={{
                  gridColumn: `span ${cols.length}`
                }}
              >
                <Expanded expanded={expandedLine === (item as any).id}>
                  <DelayRender delay={1000} isVisible={expandedLine === (item as any).id}>
                    <ExpandedLineRender item={item} />
                  </DelayRender>
                </Expanded>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}
