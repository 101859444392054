/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PosologyFrequency } from 'app/types/posology';

/**
 * Get posology frequency.
 */

export function getPosologyFrequency(client: AxiosInstance): Promise<PosologyFrequency[]> {
  return client.get(resolveAppUrl('posologyEnumFrequency'), { withCredentials: true }).then(({ data }) => data?.data);
}

/**
 * Export `useGetPosologyFrequency`.
 */

export function useGetPosologyFrequency() {
  const client = useRequestProvider();

  return useQuery(['posologyEnum', 'Frequency'], {
    queryFn: async () => {
      return await getPosologyFrequency(client);
    }
  });
}
