/**
 * Module dependencies.
 */

import { FormListField } from 'app/components/organisms/crud/form-types';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import { DosageForm } from './dosage-form';
import React from 'react';
import styles from '../orders-list.module.less';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/app-requests/crud/use-crud-request';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { PosologyDuration } from 'app/types/posology';

/**
 * Export `posologiesFormField` function.
 */

export const posologiesFormField = (translate: TFunction, prefix = ''): FormListField[] => [
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'description']).join('.'),
            label: translate('users.orders.add.labels.description'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'instructions']).join('.'),
            label: translate('users.orders.add.labels.instructions'),
            rules: undefined
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'interval']).join('.'),
            label: translate('users.orders.add.labels.interval'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'rest']).join('.'),
            label: translate('users.orders.add.labels.rest'),
            rules: requiredRule(translate)
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 6,
        children: [
          {
            type: 'selectField',
            name: compact([prefix, 'durationUnit']).join('.'),
            showSearch: true,
            allowClear: true,
            rules: requiredRule(translate),
            label: translate('users.orders.add.labels.durationUnit'),
            options: {
              hook: useCrudRequest as any,
              hookProps: () => {
                return [
                  {
                    key: ['durationUnit'],
                    options: { interpolations: {} },
                    endpoint: apiEndpoints.posologyEnumDuration
                  }
                ];
              },
              normalize: (items: PosologyDuration[]) => {
                return (
                  items?.map(item => ({
                    value: item.code,
                    label: item.descriptionLong?.pt,
                    searchValue: item.descriptionLong?.pt
                  })) ?? []
                );
              }
            }
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: compact([prefix, 'durationValue']).join('.'),
            label: translate('users.orders.add.labels.durationValue'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'render',
    name: 'render_2',
    render: (
      <div className={styles.orderItemHeader}>
        <SubTitle>{translate('users.orders.add.dosageItems')}</SubTitle>
      </div>
    )
  },
  {
    type: 'wrapper',
    name: 'wrapper',
    metadata: { prefix },
    wrapperComponent: DosageForm,
    formFields: [
      /* inside component */
    ]
  }
];
