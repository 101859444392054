/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartOutlined } from '@ant-design/icons';
import { Outlet, useParams } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { appRoutes } from 'app/routes';
import { compileMenu } from 'app/core/utils/menu';
import {
  HiMiniUsers,
  HiRectangleGroup,
  HiMiniAdjustmentsVertical,
  HiKey,
  HiMiniSignal,
  HiMiniPuzzlePiece
} from 'react-icons/hi2';
import { MdOutlineSell } from 'react-icons/md';

import { resolveAppPath } from 'app/core/utils/url-resolver';

/**
 * Export `SkinUsers` component.
 */

export function SkinUsers(): JSX.Element {
  const [translate] = useTranslation();
  const params = useParams();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: 'overview',
          pathname: appRoutes.dashboard.users.base,
          icon: <PieChartOutlined />,
          label: translate('users.menu.overview')
        },
        {
          key: 'users',
          pathname: appRoutes.dashboard.users.users,
          icon: <HiMiniUsers />,
          label: translate('users.menu.users'),
          children: !params?.userId
            ? undefined
            : [
                {
                  key: 'overviewDetail',
                  pathname: resolveAppPath(appRoutes.dashboard.users.detail.base, params),
                  icon: <PieChartOutlined />,
                  label: translate('users.menu.overview')
                },
                {
                  key: 'biometricData',
                  pathname: resolveAppPath(appRoutes.dashboard.users.detail.biometricData, params),
                  icon: <HiRectangleGroup />,
                  label: translate('users.menu.biometricData')
                },
                {
                  key: 'settings',
                  pathname: resolveAppPath(appRoutes.dashboard.users.detail.settings, params),
                  icon: <HiMiniAdjustmentsVertical />,
                  label: translate('users.menu.settings')
                },
                {
                  key: 'authorizations',
                  pathname: resolveAppPath(appRoutes.dashboard.users.detail.authorizations, params),
                  icon: <HiKey />,
                  label: translate('users.menu.authorizations')
                },
                {
                  key: 'pushTokens',
                  pathname: resolveAppPath(appRoutes.dashboard.users.detail.pushTokens, params),
                  icon: <HiMiniSignal />,
                  label: translate('users.menu.pushTokens')
                },
                {
                  key: 'pinNumbers',
                  pathname: resolveAppPath(appRoutes.dashboard.users.detail.pinNumbers, params),
                  icon: <HiMiniPuzzlePiece />,
                  label: translate('users.menu.pinNumbers')
                },
                {
                  key: 'orders',
                  pathname: resolveAppPath(appRoutes.dashboard.users.detail.orders, params),
                  icon: <MdOutlineSell />,
                  label: translate('users.menu.orders')
                }
              ]
        }
      ]),
    [params, translate]
  );

  return (
    <Dashboard menu={items}>
      <Outlet />
    </Dashboard>
  );
}
