/**
 * Export `apiEndpoints`.
 */

export const apiEndpoints = {
  aiMissingDrug: '/admin/ai/missing-drug',
  login: '/auth',
  userInfo: '/auth/me',
  experimentalFeatures: '/admin/experimental-features',
  experimentalFeaturesById: '/admin/experimental-features/${id}',
  userChangeExperimentalFeature: '/admin/users/${userId}/experimental-features',
  users: '/admin/users',
  usersById: '/admin/users/${userId}',
  usersDelete: '/admin/users/${userId}',
  usersEdit: '/admin/users/${userId}',
  dialCodes: '/admin/dial-codes',
  dialCodesById: '/admin/dial-codes/${id}',
  flags: '/mobile/dial-codes/flags/${code}',
  biometricData: '/admin/users/${userId}/biometric-data',
  userSettings: '/admin/users/${userId}/settings',
  userSettingsById: '/admin/users/${userId}/settings/${settingId}',
  userAuthorizations: '/admin/users/${userId}/authorizations',
  userAuthorizationsById: '/admin/users/${userId}/authorizations/${id}',
  userOrders: '/admin/users/${userId}/orders',
  userOrdersEdit: '/admin/users/${userId}/orders/${id}',
  userOrderItemFormCode: '/admin/users/${userId}/orders/form-code/${orderId}',
  userOrderItemToTherapy: '/admin/users/${userId}/order-item/to-therapy',
  userOrdersById: '/admin/users/${userId}/orders/${id}',
  userPushTokens: '/admin/users/${userId}/push-tokens',
  userPushTokensById: '/admin/users/${userId}/push-tokens/${id}',
  userPinNumbers: '/admin/users/${userId}/pin-numbers',
  userChangePassword: '/admin/users/${userId}/change-password',
  userPinNumbersById: '/admin/users/${userId}/pin-numbers/${id}',
  supportOpenTickets: '/admin/support',
  supportOpenTicketsById: '/admin/support/${id}',
  supportReplyTicket: '/admin/support/${id}/messages',
  supportTicketStatus: '/admin/support/${id}/status/${status}',
  languages: '/admin/languages',
  languagesById: '/admin/languages/${code}',
  devices: '/admin/devices',
  devicesById: '/admin/devices/${id}',
  drugs: '/admin/drugs',
  drugsById: '/admin/drugs/${id}',
  missingDrugs: '/admin/missing-drugs',
  missingDrugsById: '/admin/missing-drugs/${id}',
  missingDrugsDelete: '/admin/missing-drugs/${id}',
  drugsDelete: '/admin/drugs/${id}',
  translate: '/admin/translate',
  logout: '/auth/logout',
  posologyEnumDuration: '/admin/posology/enums/duration',
  posologyEnumFrequency: '/admin/posology/enums/frequency',
  posologyEnumQuantity: '/admin/posology/enums/quantity',
  pharmacies: '/admin/pharmacies',
  pharmaciesIntegrations: '/admin/pharmacies/${pharmacyId}/integrations'
} as const;
