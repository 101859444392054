/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * `Payload` type.
 */

type Payload = {
  id: string;
  message: string;
  action?: 'close' | 'open';
};

/**
 * Post ticket.
 */

export async function postTicket(client: AxiosInstance, payload: Payload): Promise<string> {
  const { id, ...data } = payload;

  return await client
    .post(resolveAppUrl('supportReplyTicket', { interpolations: { id } }), data)
    .then(({ data }) => data.data);
}

/**
 * Export `usePostTicket`.
 */

export function usePostTicket() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      return await postTicket(client, payload);
    }
  });
}
