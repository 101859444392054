/**
 * Module dependencies.
 */

import React from 'react';
import styles from './field.module.less';
import classnames from 'classnames';

/**
 * `Props` type.
 */

type Props = {
  first?: boolean;
  className?: string;
  title: string;
  danger?: boolean;
  info?: string;
  large?: boolean;
  bold?: boolean;
  last?: boolean;
  value: string | undefined | null | JSX.Element;
};

/**
 * Export `Field` component.
 */

export function Field(props: Props): JSX.Element {
  const { className, last, first, info, title, bold, danger, large, value } = props;

  return (
    <div
      className={classnames(styles.wrapper, className, {
        [styles.danger]: danger,
        [styles.large]: large,
        [styles.bold]: bold,
        [styles.last]: last,
        [styles.first]: first,
        [styles.extraInfo]: !!info
      })}
    >
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>

        <div
          className={classnames(styles.value, {
            [styles.gray]: value === 'N/A'
          })}
        >
          {value}
        </div>
      </div>

      {!!info && <div className={styles.info}>{info}</div>}
    </div>
  );
}
