/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { numericRule, requiredRule } from 'app/core/utils/field-rules';
import { useCrudRequest } from 'app/hooks/app-requests/crud/use-crud-request';
import { Pharmacy } from 'app/types/pharmacy';
import { TFunction } from 'react-i18next';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import styles from '../orders-list.module.less';
import React from 'react';
import { FormListField } from 'app/components/organisms/crud/form-types';
import { OrderItemsForm } from './order-items-form';

/**
 * Export `orderFormConfig` function.
 */

export const orderFormConfig = (translate: TFunction): FormListField[] => [
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 6,
        children: [
          {
            type: 'selectField',
            name: 'pharmacyId',
            showSearch: true,
            label: translate('users.orders.add.labels.pharmacy'),
            rules: requiredRule(translate),
            options: {
              hook: useCrudRequest as any,
              hookProps: (values: any, params: any) => {
                return [
                  {
                    key: ['pharmacies', params.userId],
                    options: { interpolations: params },
                    endpoint: apiEndpoints.pharmacies
                  }
                ];
              },
              normalize: (items: Pharmacy[]) => {
                return (
                  items?.map(item => ({
                    value: item.id,
                    label: item.name,
                    searchValue: item.name
                  })) ?? []
                );
              }
            }
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: 'externalUserId',
            label: translate('users.orders.add.labels.externalUserId'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: 'externalOrderId',
            label: translate('users.orders.add.labels.externalOrderId'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 6,
        children: [
          {
            type: 'selectField',
            name: 'status',
            label: translate('users.orders.add.labels.status'),
            rules: requiredRule(translate),
            options: [
              {
                value: 'pending',
                label: String(translate('users.orders.add.labels.statusLabels.pending'))
              },
              {
                value: 'ready',
                label: String(translate('users.orders.add.labels.statusLabels.ready'))
              },
              {
                value: 'completed',
                label: String(translate('users.orders.add.labels.statusLabels.completed'))
              }
            ]
          }
        ]
      }
    ]
  },

  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'inputField',
            name: 'vatNumber',
            label: translate('users.orders.add.labels.vatNumber'),
            rules: numericRule(translate)
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'selectField',
            name: 'provider',
            label: translate('users.orders.add.labels.provider'),
            rules: requiredRule(translate),
            options: [
              {
                value: 'integration',
                label: String(translate('users.orders.add.labels.providerLabels.integration'))
              },
              {
                value: 'backOffice',
                label: String(translate('users.orders.add.labels.providerLabels.backoffice'))
              },
              {
                value: 'mobile',
                label: String(translate('users.orders.add.labels.providerLabels.mobile'))
              }
            ]
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'if',
            name: 'isIntegration',
            watchFields: ['provider'],
            condition: ([provider]: any) => provider === 'integration',
            thenIf: [
              {
                type: 'selectField',
                name: 'integrationId',
                showSearch: true,
                label: translate('users.orders.add.labels.integration'),
                rules: requiredRule(translate),
                filterOption: (input: any, option: any) => {
                  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                },
                options: {
                  hook: useCrudRequest as any,
                  hookProps: (values: any, params: any) => {
                    if (!values.pharmacyId) {
                      return [{}];
                    }

                    return [
                      {
                        key: ['integrations', values.pharmacyId, params.userId],
                        options: { interpolations: { pharmacyId: values.pharmacyId } },
                        endpoint: apiEndpoints.pharmaciesIntegrations
                      }
                    ];
                  },
                  normalize: (items: Pharmacy[]) => {
                    return (
                      items?.map(item => ({
                        value: item.id,
                        label: item.name,
                        searchValue: item.name
                      })) ?? []
                    );
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    type: 'checkBoxField',
    name: 'isAnnulled',
    label: translate('users.orders.add.labels.isAnnulled'),
    rules: undefined
  },
  {
    type: 'render',
    name: 'invalid_bank_account',
    render: (
      <div className={styles.orderItemHeader}>
        <SubTitle>{translate('users.orders.add.orderItems')}</SubTitle>
      </div>
    )
  },
  {
    type: 'wrapper',
    name: 'wrapper',
    wrapperComponent: OrderItemsForm,
    formFields: [
      /* inside component */
    ]
  }
];
