/**
 * Module dependencies.
 */

import React, { useMemo, useState } from 'react';
import { Card } from '@tremor/react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createUsersTemplate } from '../users/form-config';
import { useCrudById } from 'app/hooks/app-requests/crud/use-crud-by-id';
import { User } from 'app/types/user';
import { Button } from 'app/components/atoms/button/button';
import styles from './user-detail-overview.module.less';
import { defaultResolveParams } from 'app/core/utils/params';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { Popconfirm } from 'antd';
import { useCrudDelete } from 'app/hooks/app-requests/crud/use-crud-delete';
import { ShowChangePassword } from './show-change-password';
import { UserDetail } from 'app/components/organisms/user-detail/user-detail';

/**
 * Export `UsersDetailOverview` component.
 */

export function UsersDetailOverview(): JSX.Element {
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const template = useMemo(() => createUsersTemplate(translate), [translate]);
  const params = useParams();
  const { data, refetch } = useCrudById<User>(template, { userId: params.userId });
  const editLink = useMemo(() => {
    const resolveParams = template.list?.resolveParams ?? defaultResolveParams;

    return !template.edit ? '#' : resolveAppPath(template.edit?.route as any, resolveParams(params, data));
  }, [data, params, template.edit, template.list?.resolveParams]);

  const { mutate: deleteAction, isLoading } = useCrudDelete(template, params, {
    onSuccess: () => {
      const routeList = template?.list?.route;

      const resolveParams = template?.list?.resolveParams ?? defaultResolveParams;
      const url = resolveAppPath(routeList ?? '', resolveParams(params));

      navigate(url);
    }
  });

  return (
    <>
      <Card>
        <UserDetail
          refetch={async () => {
            await refetch();
          }}
          user={data}
        />

        <div className={styles.actions}>
          <Link
            onClick={event => {
              event.stopPropagation();
            }}
            to={editLink}
          >
            <Button type={'primary'}>{translate('common.actions.edit')}</Button>
          </Link>

          {['backOffice', 'admin'].includes(data?.accessType ?? '') && (
            <Button onClick={() => setShowChangePassword(true)} type={'default'}>
              {translate('common.actions.changePassword')}
            </Button>
          )}

          <Popconfirm
            okButtonProps={{ loading: isLoading }}
            {...{ onClick: (event: any) => event.stopPropagation() }}
            onConfirm={async (event: any) => {
              event.stopPropagation();

              if (data) {
                await deleteAction(data);
              }
            }}
            title={translate('removeAction')}
          >
            <Button danger size={'small'}>
              {translate('common.actions.remove')}
            </Button>
          </Popconfirm>
        </div>
      </Card>

      <ShowChangePassword
        isOpen={showChangePassword}
        onClose={() => setShowChangePassword(false)}
        userId={params.userId ?? ''}
      />
    </>
  );
}
