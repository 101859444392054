/**
 * Module dependencies.
 */

import { Button, Card, Typography } from 'antd';
import { SubTitle } from 'app/components/atoms/sub-title/sub-title';
import { SupportTicket } from 'app/types/support';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { InputField } from 'app/components/atoms/forms/fields/input/input-field';
import style from './ticket-detail.module.less';
import React, { useCallback, useRef } from 'react';
import { SubmitButton } from 'app/components/atoms/forms/submit-button/submit-button';
import { requiredRule } from 'app/core/utils/field-rules';
import { usePostTicket } from 'app/hooks/app-requests/support/use-post-ticket';
import { usePostTicketState } from 'app/hooks/app-requests/support/use-post-ticket-state';

/**
 * `Props` type.
 */

type Props = {
  ticket: SupportTicket | undefined;
  refresh: () => Promise<any>;
};

/**
 * Export `ReplyMessage` component.
 */

export function ReplyMessage({ ticket, refresh }: Props): JSX.Element | null {
  const [translate] = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const form = useForm({
    values: {},
    mode: 'all'
  });

  const { mutateAsync: postTicket } = usePostTicket();
  const { mutateAsync: ticketState } = usePostTicketState();

  const onSubmit = useCallback(
    async (values: any, event: any) => {
      console.log('values', values, event);

      const type = event?.nativeEvent?.submitter?.name;

      await postTicket({
        id: ticket?.id ?? '',
        message: values.message,
        ...(type === 'submitAndClose' ? { action: 'close' } : {})
      });

      form.reset();

      await refresh();
    },
    [form, postTicket, refresh, ticket?.id]
  );

  const closeThread = useCallback(async () => {
    await ticketState({ id: ticket?.id ?? '', status: 'closed' });
    await refresh();
  }, [refresh, ticket?.id, ticketState]);

  const openThread = useCallback(async () => {
    await ticketState({ id: ticket?.id ?? '', status: 'open' });
    await refresh();
  }, [refresh, ticket?.id, ticketState]);

  if (!ticket) {
    return null;
  }

  return (
    <Card className={style.reply}>
      {ticket?.status !== 'closed' ? (
        <>
          <SubTitle>{translate('support.ticketDetail.reply')}</SubTitle>

          <form onSubmit={form.handleSubmit(onSubmit)} ref={formRef}>
            <InputField
              className={style.textArea}
              control={form.control}
              htmlType={'text'}
              inputType={'textArea'}
              label={translate('common.labels.message')}
              name={'message'}
              placeholder={translate('common.placeholders.message')}
              rules={requiredRule(translate)}
            />

            <div className={style.actions}>
              <Button danger name={'submitAndClose'} onClick={closeThread}>
                {translate('common.actions.close')}
              </Button>

              <Button danger htmlType={'submit'} name={'submitAndClose'}>
                {translate('common.actions.submitAndClose')}
              </Button>

              <SubmitButton className={style.submitAction} label={translate('common.actions.submit')} name={'submit'} />
            </div>
          </form>
        </>
      ) : (
        <div className={style.closedIssue}>
          <Typography.Text className={style.title}>{translate('support.ticketDetail.closedIssue')}</Typography.Text>

          <Button className={style.action} danger name={'submitAndClose'} onClick={openThread}>
            {translate('common.actions.reopen')}
          </Button>
        </div>
      )}
    </Card>
  );
}
