/**
 * Module dependencies.
 */

import React, { useContext } from 'react';
import { Select as AntdSelect } from 'antd';
import { get, set } from 'lodash';
import { JsonViewContext } from '@franciscomorais/react18-json-view';

/**
 * `Props` type.
 */

type Props = {
  params: any;
  node: any;
  options: Array<{
    label: any;
    value: string;
    metadata: any;
  }>;
};

/**
 * Export `PlaygroundSelector` component.
 */

export function PlaygroundSelector({ params, options }: Props): JSX.Element {
  const context = useContext(JsonViewContext);
  const field = params.parentPath.join('.');
  const value = get(context.src, field);

  return (
    <div style={{ display: 'inline-block' }}>
      <AntdSelect
        allowClear
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        onChange={id => {
          set(context.src, field, id);

          context.forceUpdate();
          context.onChange?.({ src: context.src } as any);
        }}
        options={options}
        showSearch
        style={{ width: 600 }}
        value={value}
      />
    </div>
  );
}
