/**
 * Module dependencies.
 */

import React, { useContext } from 'react';
import { Input as AntdInput } from 'antd';
import { JsonViewContext } from '@franciscomorais/react18-json-view';
import { get, set } from 'lodash';

/**
 * `Props` type.
 */

type Props = {
  params: any;
  node: any;
};

/**
 * Export `PlaygroundInput` component.
 */

export function PlaygroundInput({ params }: Props): JSX.Element {
  const context = useContext(JsonViewContext);

  const field = params.parentPath.join('.');
  const value = get(context.src, field);

  return (
    <div style={{ display: 'inline-block' }}>
      <AntdInput
        onChange={event => {
          console.log(field, event.target.value);
          set(context.src, field, event.target.value);

          context.forceUpdate();
          context.onChange?.({ src: context.src } as any);
        }}
        style={{ width: 450 }}
        value={value}
      />
    </div>
  );
}
