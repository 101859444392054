/**
 * Export `defaultResolveParams`.
 */

export function defaultResolveParams<T>(params: any, item?: T) {
  const idObj = { id: (item as any)?.id ?? params?.id };

  return {
    ...(params ?? {}),
    ...(idObj.id ? idObj : {})
  };
}
