/**
 * Module dependencies.
 */

import classnames from 'classnames';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styles from './table.module.less';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  children: JSX.Element;
  style?: any;
  expanded: boolean;
};

/**
 * Export `Expanded` component.
 */

export function Expanded({ children, expanded, className, style }: Props): JSX.Element {
  const ref = useRef<any>();
  const [height, setHeight] = useState<number | undefined>();

  const calculate = useCallback(
    (retries = 10) => {
      if (!ref.current) {
        return;
      }

      if (ref.current.offsetHeight === 0 && retries > 0) {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          calculate(retries - 1);
        }, 10);
      }

      if (expanded) {
        setHeight(ref.current.offsetHeight);
      }
    },
    [expanded]
  );

  useLayoutEffect(() => {
    calculate();
  }, [calculate, expanded]);

  return (
    <div
      className={classnames(styles.collapseRow, className)}
      style={{
        ...(style ? style : {}),
        height: expanded ? height : 0,
        width: '100%'
      }}
    >
      <div ref={ref} style={{ width: '100%' }}>
        {children}
      </div>
    </div>
  );
}
