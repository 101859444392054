/**
 * Module dependencies.
 */

import { useCrudAdd } from 'app/hooks/app-requests/crud/use-crud-add';
import { castArray, identity, reduce } from 'lodash';
import React, { useMemo } from 'react';
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CrudForm } from './crud-form';
import { CrudAddType, CrudTemplate } from './form-types';
import { resolveFormKeys } from './crud-edit';
import { CrudTemplateProvider } from './crud-template-provider';
import { useGetLanguages } from 'app/hooks/app-requests/use-get-languages';
import { Tabs } from 'antd';
import { Country } from 'app/components/atoms/country/country';
import { Language } from 'app/types/language';
import { TranslatedFieldsProvider } from 'app/components/providers/translated-fields-provider/translated-fields-provider';

/**
 * `Children` type.
 */

type Children<T> = {
  languages: Language[];
  mainLanguage: string;
  form: UseFormReturn<FieldValues, T>;
  currentLanguage: string | undefined;
};

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  data?: any;
  topChildren?: (values: Children<T>) => React.ReactNode;
  onSubmit?: (values: any, cb: (values: any) => Promise<void>) => Promise<void>;
};

/**
 * Default normalize inicial values.
 */

function defaultNormalizeInitialValues(formKeys: string[], initialValues: any): any {
  return initialValues;
}

/**
 * Default submit.
 */

function defaultSubmit(values: any, cb: (values: any) => Promise<void>): any {
  return cb(values);
}

/**
 * Export `CrudAdd` component.
 */

export function CrudAdd<T>({ template, data, topChildren, onSubmit = defaultSubmit }: Props<T>): JSX.Element {
  const {
    formFields,
    redirect,
    normalizePayload = identity,
    submitLabel,
    normalizeInitialValues = defaultNormalizeInitialValues
  } = template.add as CrudAddType<T>;

  const [currentLanguage, setCurrentLanguage] = React.useState(template.edit?.languages?.[0]);
  const routeList = template?.list?.route;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const initialValues = useMemo(() => {
    if (data) {
      return data;
    }

    const result = reduce(
      template.add?.acceptQueryFields,
      (acc: any, field: any) => {
        const { name, parser } =
          typeof field !== 'string'
            ? field
            : {
                name: field,
                parser: identity
              };

        return {
          [name]: parser(searchParams.get(name))
        };
      },
      {}
    );

    return result;
  }, [data, searchParams, template.add?.acceptQueryFields]);

  const formKeys = useMemo(() => {
    if (!template.add?.formFields) {
      return [];
    }

    return resolveFormKeys(formFields);
  }, [formFields, template.add?.formFields]);

  const { mutate: add } = useCrudAdd(template, params);
  const navigate = useNavigate();
  const form = useForm({
    values: normalizeInitialValues?.(formKeys, initialValues as any),
    mode: 'all'
  });

  console.log(form.formState.errors);

  const { data: availableLanguages } = useGetLanguages();
  const languages = useMemo(() => {
    if (typeof template?.add?.languages === 'string' && template?.add?.languages === '*') {
      setCurrentLanguage(availableLanguages?.[0].code);
      return availableLanguages ?? [];
    }

    const langs = castArray(template?.add?.languages ?? []).map(item => item.toLowerCase());

    return (availableLanguages ?? []).filter(item => {
      return langs.includes(item.code.toLowerCase());
    });
  }, [availableLanguages, template?.add?.languages]);

  const handleSubmit = async (values: any) => {
    onSubmit(values, async data => {
      return add(normalizePayload(data), {
        onSuccess: () => {
          navigate(redirect ?? routeList ?? '');
        }
      });
    });
  };

  return (
    <CrudTemplateProvider template={template}>
      {template.add?.languages && (
        <Tabs
          defaultActiveKey={'1'}
          items={languages.map(item => ({
            key: item.code,
            label: <Country extraPadding item={item} />
          }))}
          onChange={item => {
            setCurrentLanguage(item);
          }}
        />
      )}

      {topChildren?.({ languages, form, mainLanguage: languages?.[0]?.code, currentLanguage })}

      <TranslatedFieldsProvider>
        <CrudForm
          currentLanguage={currentLanguage}
          form={form}
          formFields={formFields}
          formType={'add'}
          mainLanguage={languages?.[0]?.code}
          metadata={{}}
          onSubmit={handleSubmit as any}
          submitLabel={submitLabel}
          template={template}
        />
      </TranslatedFieldsProvider>
    </CrudTemplateProvider>
  );
}
