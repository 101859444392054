/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Order } from 'app/types/order';

/**
 * Get user order item form code.
 */

export function getUserOrderItemFormCode(
  client: AxiosInstance,
  userId: string | undefined,
  orderId: string
): Promise<Order[]> {
  return client
    .get(
      resolveAppUrl('userOrderItemFormCode', {
        interpolations: { userId, orderId }
      }),
      { withCredentials: true }
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useCryptoOverviewInvalidator`.
 */

export function useCryptoOverviewInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['userInfo']);
  }, [queryClient]);
}

/**
 * Export `useGetOrderItemFormCode`.
 */

export function useGetOrderItemFormCode(userId: string | undefined, orderId: string | undefined) {
  const client = useRequestProvider();

  return useQuery(['orderItemFormCode', userId, orderId], {
    queryFn: async () => {
      if (!userId || !orderId) {
        return [];
      }

      return getUserOrderItemFormCode(client, userId, orderId);
    }
  });
}
