/**
 * Module dependencies.
 */

import { CrudEdit } from 'app/components/organisms/crud/crud-edit';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { appRoutes } from 'app/routes';
import { Order } from 'app/types/order';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { orderFormConfig } from './order-items/form-order-config';
import moment from 'moment';
import { Title } from 'app/components/atoms/title/title';

/**
 * Export `OrderEdit` component.
 */

export function OrderEdit(): JSX.Element {
  const [translate] = useTranslation();
  const { userId } = useParams();
  const template = useMemo((): CrudTemplate<Order> => {
    return {
      list: {
        endpointById: apiEndpoints.userOrdersById
      } as any,
      edit: {
        formFields: orderFormConfig(translate),
        languages: ['*'],
        submitLabel: translate('common.actions.add'),
        normalizePayload: payload => {
          const cleanPayload = JSON.parse(JSON.stringify(payload));

          cleanPayload.createdAt = moment().toISOString();

          for (const item of cleanPayload.items ?? []) {
            item.quantity = parseInt(item.quantity, 10);
          }

          if (!cleanPayload.integrationId) {
            delete cleanPayload.integrationId;
          }

          delete cleanPayload.id;
          delete cleanPayload.pharmacy;
          delete cleanPayload.userId;
          delete cleanPayload.integrationCreatedAt;
          delete cleanPayload.updatedAt;
          delete cleanPayload.createdAt;

          for (const item of cleanPayload.items ?? []) {
            delete item.orderId;
            delete item.createdAt;
            delete item.updatedAt;
            delete item.deletedAt;
            delete item.posologyId;

            for (const it of item.posology ?? []) {
              delete it.itemId;
              delete it.productId;
              delete it.productCode;
              delete it.productName;
              delete it.createdAt;
              delete it.updatedAt;
              delete it.metadata;

              for (const dos of it.dosage ?? []) {
                delete dos.posologyId;
                delete dos.createdAt;
                delete dos.updatedAt;
                delete dos.dosageIteration;
              }
            }
          }

          return cleanPayload;
        },
        normalizeInitialValues: (keys, values) => {
          return values;
        },
        redirect: resolveAppPath(appRoutes.dashboard.users.detail.orders, { userId }),
        endpoint: apiEndpoints.userOrdersEdit
      }
    };
  }, [translate, userId]);

  return (
    <>
      <Title>{translate('users.orders.edit.title')}</Title>

      <CrudEdit template={template} />
    </>
  );
}
