/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Language } from 'app/types/language';

/**
 * Get experimental features.
 */

export function getExperimentalFeatures(client: AxiosInstance): Promise<Language[]> {
  return client.get(resolveAppUrl('experimentalFeatures'), { withCredentials: true }).then(({ data }) => data?.data);
}

/**
 * Export `useGetExperimentalFeatures`.
 */

export function useGetExperimentalFeatures() {
  const client = useRequestProvider();

  return useQuery(['sys-experimental-features'], {
    queryFn: async () => {
      return await getExperimentalFeatures(client);
    }
  });
}
