/**
 * Module dependencies.
 */

import React, { Fragment } from 'react';
import { CrudTemplate, WrapperWidget } from '../form-types';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Language } from 'app/types/language';
import { CrudFormRender } from '../crud-form';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  languages: Language[];
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  metadata: any;
  formType: 'add' | 'edit';
  name: string;
  item: WrapperWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `WrapperField` component.
 */

export function WrapperField<T>(props: Props<T>): JSX.Element {
  const { form, template, languages, currentLanguage, mainLanguage, metadata, item, formType } = props;
  const Component = item.wrapperComponent ?? Fragment;

  return (
    <Component {...props}>
      <CrudFormRender
        currentLanguage={currentLanguage}
        form={form}
        formFields={item.formFields}
        formType={formType}
        languages={languages}
        mainLanguage={mainLanguage}
        metadata={metadata}
        template={template}
      />
    </Component>
  );
}
