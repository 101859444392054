// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-overview-module__wrapper--VSwwk {
  padding: 16px 0;
  border-radius: 16px;
  flex-direction: column;
  display: flex;
}
.user-overview-module__wrapper--VSwwk .user-overview-module__content--qxv8E {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
}
.user-overview-module__wrapper--VSwwk .user-overview-module__content--qxv8E .user-overview-module__column--qxYMD {
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
  min-width: 400px;
}
.user-overview-module__wrapper--VSwwk .user-overview-module__userInfo--W92r4 {
  max-width: 400px;
}
`, "",{"version":3,"sources":["webpack://./components/pages/users/overview/user-overview.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;AADF;AAHA;EAOI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AADJ;AATA;EAaM,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AADN;AAfA;EAqBI,gBAAA;AAHJ","sourcesContent":["\n\n.wrapper {\n  padding: 16px 0;\n  border-radius: 16px;\n  flex-direction: column;\n  display: flex;\n\n  .content {\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n    margin-top: 16px;\n\n    .column {\n      border: 1px solid #e0e0e0;\n      padding: 16px;\n      border-radius: 8px;\n      min-width: 400px;\n    }\n  }\n\n  .userInfo {\n    max-width: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `user-overview-module__wrapper--VSwwk`,
	"content": `user-overview-module__content--qxv8E`,
	"column": `user-overview-module__column--qxYMD`,
	"userInfo": `user-overview-module__userInfo--W92r4`
};
export default ___CSS_LOADER_EXPORT___;
